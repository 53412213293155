import React from "react"
import PropTypes from "prop-types"
import {
  QuestionnaireAnswers,
  QuestionnaireAnswerButton,
} from "@thedgbrt/logology-ui-components"

const AnswerButtons = ({ answers, oneColumn, handleSelect, selectedId }) => (
  <QuestionnaireAnswers oneColumn={oneColumn}>
    {answers.map((answer, index) => (
      <QuestionnaireAnswerButton
        handleClick={() => handleSelect(answer.id)}
        selected={`_${answer.id}` === selectedId}
        key={index}
      >
        {answer.title}
      </QuestionnaireAnswerButton>
    ))}
  </QuestionnaireAnswers>
)

AnswerButtons.propTypes = {
  answers: PropTypes.array.isRequired,
}

export default AnswerButtons
