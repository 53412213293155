import React, { useState } from "react"
import {
  Button,
  FormInput,
  QuestionnaireActionBtns,
  Text,
  Title,
} from "@thedgbrt/logology-ui-components"
import AnswerButtons from "../QuestionnaireApp/AnswerButtons"
import { activities } from "./defines"

const ActivitySelector = ({
  buttonLabel,
  initDetails,
  initSelectedId,
  successCallback,
  smallLabels,
}) => {
  const [hasSelected, setHasSelected] = useState(false)
  const [selectedId, setSelectedId] = useState(initSelectedId)
  const [detailsValue, setDetailsValue] = useState(initDetails)

  const handleSelect = id => {
    setHasSelected(true)
    setSelectedId(id)
  }

  const handleSubmit = () =>
    successCallback(selectedId, activities[selectedId].title, detailsValue)

  if (hasSelected)
    return (
      <>
        <Title level={3}>My activity</Title>
        <Text lead={smallLabels !== true}>
          Details about your activity will help us prioritize future logo
          designs.
        </Text>
        <FormInput
          placeholder="What do you do?"
          type="text"
          name="activityDetails"
          id="activity-details"
          onChange={e => setDetailsValue(e.target.value)}
          value={detailsValue}
        />

        <QuestionnaireActionBtns>
          <Button onClick={handleSubmit}>{buttonLabel}</Button>
        </QuestionnaireActionBtns>
      </>
    )

  return (
    <>
      <Title level={3}>My activity</Title>
      <AnswerButtons
        answers={Object.keys(activities)
          .map(k => activities[k])
          .sort((a, b) => (a.sort < b.sort ? -1 : a.sort > b.sort))}
        handleSelect={handleSelect}
        selectedId={initSelectedId}
      />
    </>
  )
}

export default ActivitySelector
