import React from "react"
import { connect } from "react-redux"
import {
  BlackBox,
  makeRem,
  GridBox,
  GridContainer,
  Standalone,
  Text,
} from "@thedgbrt/logology-ui-components"
import { navigate } from "gatsby"
import API, { graphqlOperation } from "@aws-amplify/api"
import { saveProfileActivity } from "../../state/actions/questionnaire"
import { trackAmplitude } from "../../helpers/amplitude"
import { trackSegmentEvent } from "../../helpers/segment"
import ActivitySelector from "../ActivitySelector"

const SelectActivity = ({
  initDetails,
  initSelectedId,
  saveProfileActivity,
}) => {
  const successCallback = async (id, title, details) => {
    saveProfileActivity(API, graphqlOperation, {
      id,
      details,
      stage: 1,
    }).then(res => {
      if (res === true) {
        trackSegmentEvent("track", "Select activity", {
          activityId: id,
          activityName: title,
          activityDetails: details,
        })

        trackAmplitude("Select activity", {
          activityId: id,
          activityName: title,
          activityDetails: details,
        })

        navigate("/myaccount/proposals/")
      }
      return
    })
  }

  return (
    <>
      <GridContainer type={3}>
        <GridBox
          colSpans={[[2, 5], [2, 4], [2, 4]]}
          type={3}
          withBorder
          style={{ background: "#000000" }}
        >
          <BlackBox>
            <Text
              standalone
              style={{ marginBottom: makeRem(10), marginTop: makeRem(8) }}
            >
              We added new activities since you last logged in. Please pick
              yours to continue.
            </Text>
          </BlackBox>
        </GridBox>
      </GridContainer>
      <GridContainer type={3}>
        <GridBox colSpans={[[2, 5], [3, 5], [3, 6]]} type={3}>
          <Standalone>
            <ActivitySelector
              buttonLabel="confirm →"
              initDetails={initDetails}
              initSelectedId={0}
              successCallback={successCallback}
              smallLabels
            />
          </Standalone>
        </GridBox>
      </GridContainer>
    </>
  )
}

export default connect(
  ({ questionnaire }) => ({
    initSelectedId: `_${questionnaire.activityId}`,
    initDetails: questionnaire.activityName,
  }),
  { saveProfileActivity }
)(SelectActivity)
