// "sort" is useful to keep "other" last
// const activitiesOld = {
//   51: {
//     id: 51,
//     sort: "Artificial Intelligence",
//     title: "Artificial Intelligence",
//   },
//   65: { id: 65, sort: "Biotech", title: "Biotech" },
//   49: { id: 49, sort: "Cloud Computing", title: "Cloud Computing" },
//   61: { id: 61, sort: "Code & Development", title: "Code & Development" },
//   55: { id: 55, sort: "Communication", title: "Communication" },
//   54: { id: 54, sort: "Crypto & Blockchain", title: "Crypto & Blockchain" },
//   53: { id: 53, sort: "Cyber Security", title: "Cyber Security" },
//   41: { id: 41, sort: "Dating", title: "Dating" },
//   62: { id: 62, sort: "Design", title: "Design" },
//   43: { id: 43, sort: "Education", title: "Education" },
//   52: { id: 52, sort: "Finance", title: "Finance" },
//   46: { id: 46, sort: "Gaming", title: "Gaming" },
//   45: { id: 45, sort: "Human Resources", title: "Human Resources" },
//   60: { id: 60, sort: "Marketing & Sales", title: "Marketing & Sales" },
//   42: { id: 42, sort: "Podcast", title: "Podcast" },
//   44: { id: 44, sort: "Productivity", title: "Productivity" },
//   50: { id: 50, sort: "Robotics", title: "Robotics" },
//   48: { id: 48, sort: "ZZZ", title: "Other" },
// }

const activities = {
  2: {
    id: 2,
    sort: "Agriculture & Energy",
    title: "Agriculture & Energy",
  },
  8: {
    id: 8,
    sort: "Artificial Intelligence",
    title: "Artificial Intelligence",
  },
  1: {
    id: 1,
    sort: "Beauty & Fashion",
    title: "Beauty & Fashion",
  },
  18: {
    id: 18,
    sort: "Code & Development",
    title: "Code & Development",
  },
  5: {
    id: 5,
    sort: "Communication & Media",
    title: "Communication & Media",
  },
  4: { id: 4, sort: "Design", title: "Design" },
  12: { id: 12, sort: "Education", title: "Education" },
  10: { id: 10, sort: "Finance", title: "Finance" },
  15: {
    id: 15,
    sort: "Food & Restaurant",
    title: "Food & Restaurant",
  },
  14: { id: 14, sort: "Gaming", title: "Gaming" },
  16: {
    id: 16,
    sort: "Health & Life Sciences",
    title: "Health & Life Sciences",
  },
  13: {
    id: 13,
    sort: "Human Resources",
    title: "Human Resources",
  },
  26: { id: 26, sort: "Love & Dating", title: "Love & Dating" },
  19: {
    id: 19,
    sort: "Marketing & Sales",
    title: "Marketing & Sales",
  },
  20: {
    id: 20,
    sort: "Media, Music, Image",
    title: "Media, Music, Image",
  },
  11: { id: 11, sort: "Productivity", title: "Productivity" },
  21: {
    id: 21,
    sort: "Real Estate & Construction",
    title: "Real Estate & Construction",
  },
  3: {
    id: 3,
    sort: "Security & Blockchain",
    title: "Security & Blockchain",
  },
  22: {
    id: 22,
    sort: "Sport & Fitness",
    title: "Sport & Fitness",
  },
  17: { id: 17, sort: "ZZZ", title: "Other" },
}

export { activities }
